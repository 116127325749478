import React, { useState, useEffect, useRef } from 'react';
import './App.css';

function App() {
  const [message, setMessage] = useState('');
  const [chatHistory, setChatHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [initialPrompt, setInitialPrompt] = useState('How can I assist you today?');

  // Ref to keep track of the last message element
  const lastMessageRef = useRef(null);

  // Scroll to the last message in the chat container
  const scrollToBottom = () => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // Automatically scroll to the bottom whenever chat history changes
  useEffect(() => {
    scrollToBottom();
  }, [chatHistory]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (message.trim() === '') return;

    setLoading(true);

    const userMessage = { sender: 'You', text: message };
    setChatHistory((prevHistory) => [...prevHistory, userMessage]); // Add user's message to chat history

    const res = await fetch(`${window.location.protocol}//${window.location.host}/api/chat`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ prompt: message }),
    });

    const data = await res.json();
    const botMessage = { sender: 'Optimus', text: formatResponse(data.response) };

    setChatHistory((prevHistory) => [...prevHistory, botMessage]); // Add only bot's response to chat history
    setLoading(false);
    setMessage(''); // Clear input field after sending
    setInitialPrompt('');  // Clears the initial prompt after first input
  };

  // Function to format URLs and emails in the bot's response
  const formatResponse = (text) => {
    const urlPattern = /https?:\/\/[^\s/$.?#].[^\s]*/g;
    const emailPattern = /\b[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}\b/gi;

    let formattedText = text.replace(urlPattern, (url) => `<a href="${url}" target="_blank">${url}</a>`);
    formattedText = formattedText.replace(emailPattern, (email) => `<a href="mailto:${email}">${email}</a>`);

    return formattedText;
  };

  // Function to render each message
  const renderMessage = (message, index) => {
    const isUser = message.sender === 'You';

    return (
      <div key={index} className={`message ${isUser ? 'user-message' : 'bot-message'}`} ref={index === chatHistory.length - 1 ? lastMessageRef : null}>
        <span dangerouslySetInnerHTML={{ __html: message.text }} />
      </div>
    );
  };

  return (
    <div className="App">
      <main>
        <div className="chat-container">
          {initialPrompt && <div className="initial-prompt">{initialPrompt}</div>}
          {chatHistory.map((message, index) => renderMessage(message, index))}
          {loading && <div className="botMessage">Optimus is thinking...</div>}
        </div>
        <form onSubmit={handleSubmit} className="input-form">
          <input
            type="text"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Type your message here..."
            disabled={loading}
          />
          <button type="submit" disabled={loading}>
            {loading ? 'Sending...' : 'Send'}
          </button>
        </form>
      </main>
    </div>
  );
}

export default App;
